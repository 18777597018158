<template>
  <b-form class="w-100 form-class">
    <b-row>
      <b-col lg="12">
        <label class="required">Заголовок</label>
        <b-form-input
          v-model="form.title"
          trim
          required
          autofocus
          placeholder="Введите заголовок новости"
        />
      </b-col>

      <b-col lg="12 mt-4">
        <label class="required">Содержимое</label>
        <b-form-textarea
          v-model="form.body"
          required
          placeholder="Вставьте сюда HTML содержимое"
        />
      </b-col>

      <b-col lg="12 mt-4">
        <label for="tags-pills">Теги новости</label>
        <b-form-tags
          input-id="tags-pills"
          v-model="form.tags"
          tag-variant="primary"
          tag-pills
          remove-on-delete
          placeholder="Нажмите Enter для добавления тега"
        />
      </b-col>

      <b-col lg="12 mt-4">
        <label>Картинка новости</label>
        <b-form-file
          v-model="form.file"
          placeholder="Выберите файл"
          browse-text="Выбрать"
        />
      </b-col>
    </b-row>

    <div class="mt-4 btn btn-second add-item__btn" @click="save" type="submit">
      Сохранить
    </div>
  </b-form>
</template>

<script>
export default {
  name: "NewsForm",
  data() {
    return {
      form: {
        title: "",
        body: "",
        tags: [],
        file: null
      }
    };
  },
  methods: {
    save() {
      this.$emit("save", this.form);
    }
  }
};
</script>

<style scoped lang="scss">
.required {
  &::after {
    content: "*";
    font-size: 24px;
    color: red;
  }
}

.form-class {
  background-color: white;
  border: 1px black solid;
  padding: 15px 20px;
  border-radius: 5px;
}
</style>
