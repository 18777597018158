<template>
  <tepmplate-block
    mod-class
    content-class="overflow-auto main-wrap pt-4"
    title-page="Создание новости"
  >
    <news-form @save="save" />
  </tepmplate-block>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import NewsForm from "../../components/News/NewsForm";
import { FilesApi } from "../../function/request/apiV2";
export default {
  name: "NewsCreate",
  components: { NewsForm, TepmplateBlock },
  data() {
    return {
      filesApi: new FilesApi()
    };
  },
  computed: {
    companyId() {
      return this.$store.getters.currentUser?.company?.toString();
    }
  },
  methods: {
    async loadFile(file) {
      const response = await this.filesApi.upload(file);
      console.log(response);
      return 123;
    },
    async save(form) {
      const file = form?.file ? await this.loadFile(form.file) : undefined;
      const newsForm = {
        title: form?.title ?? "",
        body: form?.body ?? "",
        preview: file,
        tags: form?.tags ?? [],
        companyId: this.companyId
      };

      console.log(newsForm);

      this.$bvToast.toast("Успех", {
        variant: "success",
        title: "Новость создана"
      });
    }
  }
};
</script>
